@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

body, html {
    overflow: hidden;
    font-size: 1rem;
    line-height: 150%;
}

.bp5-multi-select {
    overflow: auto !important;
    height: 30px !important;
}

.noPadding {
    padding: 0 !important;
}

.bp5-dark .bp5-html-table .td {
    padding: 11px;
}
.bp5-dark .bp5-html-table.bp5-html-table-striped .tr:nth-child(odd) .td {
    background: rgba(95,107,124,.15);
}
.bp5-dark .bp5-html-table.bp5-interactive .tr:hover .td {
    background-color: rgba(95,107,124,.3);
    cursor: pointer;
}

.flat {
    box-shadow: none !important;
}

.bp5-dark {
    background: #293742 !important;
}

.bp5-dark .bp5-menu {
    background: #30404d !important;
    color: #f5f8fa !important;
}

.bp5-dark .bp5-input {
    box-shadow: 0 0 0 0 rgba(45,114,210,0), 0 0 0 0 rgba(45,114,210,0), inset 0 0 0 1px rgba(17,20,24,.2), inset 0 1px 1px rgba(17,20,24,.5) !important;
}

.bp5-navbar {
    background-color: #394b59 !important;
    box-shadow: 0 0 0 1px rgba(16,22,26,.2), 0 0 0 rgba(16,22,26,0), 0 1px 1px rgba(16,22,26,.4) !important;
    height: 50px !important;
    padding: 0 15px;
    position: relative !important;
    width: 100% !important;
    z-index: 10 !important;
}

.bp5-panel-stack-view {
    background-color: #30404d !important;
}

.bp5-dark .bp5-elevation-0, .bp5-elevation-0.bp5-dark {
    box-shadow: 0 0 0 1px rgba(16,22,26,.4),0 0 0 rgba(16,22,26,0),0 0 0 rgba(16,22,26,0);
}

.bp5-card.bp5-dark, .bp5-dark .bp5-card {
    background-color: #30404d;
    box-shadow: 0 0 0 1px rgba(16,22,26,.4),0 0 0 rgba(16,22,26,0),0 0 0 rgba(16,22,26,0);
}

.bp5-dark .bp5-button:not([class*=bp5-intent-]):not([class*=bp5-minimal]).bp5-disabled, .bp5-dark .bp5-button:not([class*=bp5-intent-]):not([class*=bp5-minimal]):disabled {
    background-color: rgba(57,75,89,.5) ;
    background-image: none ;
    box-shadow: none ;
    color: rgba(167,182,194,.6) ;
}

.bp5-dark .bp5-button:not([class*=bp5-intent-]):not([class*=bp5-minimal]).bp5-disabled, .bp5-dark .bp5-button:not([class*=bp5-intent-]):not([class*=bp5-minimal]):disabled {
    background-color: rgba(57,75,89,.5) ;
    background-image: none ;
    box-shadow: none ;
    color: rgba(167,182,194,.6) ;
}

.bp5-dark .bp5-button.bp5-minimal.bp5-disabled, .bp5-dark .bp5-button.bp5-minimal.bp5-disabled:hover, .bp5-dark .bp5-button.bp5-minimal:disabled, .bp5-dark .bp5-button.bp5-minimal:disabled:hover {
    background: none ;
    color: rgba(167,182,194,.6) ;
    cursor: not-allowed ;
}

.bp5-dark .bp5-button:not([class*=bp5-intent-]):not([class*=bp5-minimal]) {
    background-color: #394b59 ;
    background-image: linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0)) ;
    box-shadow: 0 0 0 1px rgba(16,22,26,.4) ;
    color: #f5f8fa ;
}

.bp5-button:not([class*=bp5-intent-]):not([class*=bp5-minimal]) {
    background-color: #f5f8fa ;
    background-image: linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0)) ;
    box-shadow: inset 0 0 0 1px rgba(16,22,26,.2),inset 0 -1px 0 rgba(16,22,26,.1) ;
    color: #182026 ;
}

.bp5-dark .bp5-button:not([class*=bp5-intent-]):not([class*=bp5-minimal]).bp5-active, .bp5-dark .bp5-button:not([class*=bp5-intent-]):not([class*=bp5-minimal]):active {
    background-color: #202b33;
    background-image: none;
    box-shadow: 0 0 0 1px rgba(16,22,26,.6),inset 0 1px 2px rgba(16,22,26,.2);
}

.bp5-dark .bp5-button:not([class*=bp5-intent-]):not([class*=bp5-minimal]):hover {
    background-color: #30404d;
    box-shadow: 0 0 0 1px rgba(16,22,26,.4);
}

.bp5-dark .bp5-button:not([class*=bp5-intent-]):not([class*=bp5-minimal]).bp5-active, .bp5-dark .bp5-button:not([class*=bp5-intent-]):not([class*=bp5-minimal]):active, .bp5-dark .bp5-button:not([class*=bp5-intent-]):not([class*=bp5-minimal]):hover {
    color: #f5f8fa;
}

.full-height {
    height: 100%
}

.main-area {
    width: 100%;
    height: calc(100vh - 50px);
    overflow: hidden;
}

.custom-tab .bp5-tab-indicator-wrapper {
    display: none;
}

.custom-tab .bp5-tab-list > *:not(:last-child) {
    margin-right: 5px !important;
}

.search-group {
    width: 1200px;
    position: relative;
    left: 50%;
    margin-left: -600px;
}
.search-group .bp5-control-group {
    width: 100%;
}

.small-image {
    width: 120px; 
    padding-top: 120px;
    height: 50px;
    background-position: center center;
    background-size: cover;
    background-color: black;
    background-repeat: no-repeat;
    float: left;
    margin: 5px;
    text-align: center;
}

.tag-counter {
    margin-left: 5px !important;
    font-size: 10px !important;
    padding: 4px !important;
    line-height: 8px !important;
    min-width: 10px !important;
    min-height: 10px !important;
}

.card-tag {
    max-width: 250px;
}

@media screen and (max-width: 1200px) {
  .hide1300 {
      display: none;
  }
  .scrollX1300 {
      overflow-x: auto;
  }
}

.singleGroupButton .bp5-button-text {
    display: flex !important;
    width: 100% !important;
}

.singleGroupButton.notexpanded .bp5-button-text {
    flex-wrap: wrap;
}

.singleGroupButton.notexpanded .bp5-button-text .articleText {
    flex-basis: 100%;
}

.highlightKeyword {
    text-decoration: underline;
    text-decoration-color: #D9822B;
    text-decoration-thickness: 5px;
}

.scroll-marker {
    width: 8px;
    pointer-events: none;
    margin-left: -14px;
    height: 100%;
    float: left;
}

.scroll-marker div {
    background-color: #D9822B;
    height: 1px;
    position: relative; 
    width: 100%;
}

.bp5-panel-stack-header {
    z-index: 2;
}

.nd-Editor {
    height: 100% !important;
    color: white !important;
}

.w-md-editor-show-live .nd-Editor {
    width: 50% !important;
}

.w-md-editor-show-edit .nd-Editor {
    width: 100% !important;
}

.nd-Editor textarea, .nd-Editor .wmde-markdown-color, .nd-Editor * , .nd-Editor .title {
    color: white !important;
}

.w-md-editor, .w-md-editor-content {
    background-color: #30404d !important;
    color: white !important;
}

.w-md-editor-content {
    border-top: 1px solid #293742 !important;
    height: calc(100% - 50px) !important;
}

.w-md-editor-bar {
    display: none;
}

.w-md-editor-text code, .w-md-editor-text textarea {
    font-family: Arial;
    line-height: 1.6;
    font-size: 1rem;
}

.w-md-editor-toolbar {
    border-radius: 0 !important;
    background-color: #394b59 !important;
    color: white;
    border: 0 !important;
    box-shadow: 0 0 0 1px rgb(16 22 26 / 20%), 0 0 0 rgb(16 22 26 / 0%), 0 1px 1px rgb(16 22 26 / 40%);
    height: 50px !important;
    margin-top: 1px;
}
.w-md-editor-toolbar svg {
    color: white !important;
}

.w-md-editor-toolbar li button:hover svg, .w-md-editor-toolbar li.active svg {
    color: #394b59 !important;
}

.bp5-panel-stack-header {
    height: 50px;
}

.bp5-panel-stack-header button {
    width: 50px;
    height: 50px;
}

.bp5-panel-stack-header .bp5-control-group {
    position: absolute;
    left: 0;
    top: 0;
}
.bp5-panel-stack-header .bp5-control-group button {
    width: auto;
    height: 49px;
}

div.player {
  display: inline-block;
  position: relative;
}
div.player img {
  max-width: 100%;
}
div.player:after {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  width: 96px;
  height: 96px;
  background: url('play.png') transparent top left no-repeat;
  background-size: cover;
  content: '';
  opacity: .5;
}

.videoOverlay {
    width: 845px !important;
}

.hide-header .list-expanded .bp5-panel-stack-header {
    display: none !important;
}

.btn-no-padding {
    padding: unset !important;
}

.leaflet-draw.leaflet-control {
    visibility: hidden;
}

.leaflet-popup-content-wrapper .leaflet-popup-useraction-buttons {
    display: none;
}
.leaflet-popup-content-wrapper:hover .leaflet-popup-useraction-buttons {
    display: flex;
}
.leaflet-popup-content-wrapper .editing .leaflet-popup-useraction-buttons {
    display: flex;
}
.leaflet-popup-content {
    margin: 5px 10px;
}

.leaflet-popup-button.remove {
    white-space: nowrap;
    width: 45px;
    overflow: hidden;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
    background-color: #293742 !important;
    color: white !important;

}

.leaflet-bar a {
    border-bottom: 0 !important;
}

.comments-list .bp5-card .bp5-card {
    background: #394B59; 
}

em.highlight {
    color: #D9822B;
}

.travelFull em.highlight {
    text-decoration: underline;
    text-decoration-color: #D9822B;
    text-decoration-thickness: 5px;
}

.sourceTree ul { 
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.sourceTree li {
    padding: 5px;
}


h3 {
    margin-top: 0;
}

.AIList .bp5-button-text {
    max-height: 64px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.1rem !important;
}

.hidden {
    visibility: hidden !important;
}

.filter-panel {
    height: calc(75vh - 64px) !important;
    overflow: auto;
    width: 100% !important;
}

.filter-panel-sub {
    height: calc(75vh - 130px) !important;
    overflow: auto;
    width: 100% !important;
}

.limit-popup {
    max-height: 500px !important;
    overflow-y: auto !important;
    overflow-x: hidden;
}